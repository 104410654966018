import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { AboutImage, GamesImage, RockChoirImage, ReadiophonicsImage, RVAImage, IngressImage, SignLanguageImage } from "../components/images";

const IndexPage = () => (
  <Layout>
    <SEO title="Yeggstry" keywords={[`yeggstry`, `software developer`, `singing`, `scrum master`]} />
    <section className="section-intro">
      <p class="tagline">Making lives easier through technology</p>
      <p>Hello there! My name is Lewis, and I am a professional Software Developer.</p>
    </section>
    <hr />
    <section className="section-a">
      <section className="section-a1">
        <AboutImage />
        <p><strong>So why the nickname "Yeggstry"?</strong></p>
        <p>To answer that question, we have to go back to when I was just six years old. My first computer I owned was an Atari ST 1040, and the third 
          video game I ever played was Jimmy White's Whirlwind Snooker.</p>
        <p>I would often play against the computer, and I would have to give the computer a name. My small fist hit the keyboard and came up 
          with "Yeggstry". I've been using the nickname ever since, for over 30 years!</p>
      </section>
      <section className="section-a2">
        <GamesImage />
        <p>Video games is one of the ways I relax and unwind. I enjoy strategy, adventure and co-op FPS genres.</p>
        <p>This month I am mostly playing:</p>
        <ul>
          <li><a href="https://store.steampowered.com/app/550/Left_4_Dead_2/" title="Left 4 Dead 2" target="_blank" rel="noopener noreferrer">Left 4 Dead 2</a></li>
          <li><a href="https://innersloth.com/gameAmongUs.php" title="Among Us" target="_blank" rel="noopener noreferrer">Among Us</a></li>
          <li><a href="https://www.overkillsoftware.com/games/payday-2/" title="Payday 2"  target="_blank" rel="noopener noreferrer">Payday 2</a></li>
          <li><a href="https://www.back4blood.com/" title="Back 4 Blood" target="_blank" rel="noopener noreferrer">Back 4 Blood</a></li>
        </ul>
      </section>
    </section>
    <hr />
    <section className="section-b">
      <h4>Professional Experience</h4>
      <p>A proactive and highly adaptable software developer with 12 years of commercial development and architectural experience in building web applications.</p>
      <p>As a certified professional Scrum Master I have practiced the empirical process for 5 years, leading to a team-based mentality to continuously improve 
        and deliver at a high level of quality.</p>
      <p>I have worked in the following areas during my career:</p>
      <ul>
        <li>E-commerce</li>
        <li>Personal Insurance (Travel, Life etc.)</li>
        <li>Health systems</li>
        <li>Image processing and earth observation</li>
        <li>Slot machine and table game creation for gambling websites</li>
        <li>Sport data processing and exposure of said data via APIs.</li>
      </ul>
      <p>For more detail, please visit my <a href="https://www.linkedin.com/in/lewisrkeen" title="LinkedIn" target="_blank" rel="noopener noreferrer">LinkedIn</a> profile.</p>
    </section>
    <hr />
    <section className="section-c">
      <h4>Personal Projects</h4>
      <p>Coming soon!</p>
    </section>
    <hr />
    <section className="section-d">
      <h4>Personal Interests</h4>
      <ul>
        <li>
          <a href="https://www.rockchoir.com/" title="Rock Choir" target="_blank" rel="noopener noreferrer">
            <RockChoirImage />
          </a>
          <div>
            <p>One of my passions is the ability to sing. In Easter 2017 I joined the <a href="https://www.rockchoir.com/" title="Rock Choir" target="_blank" rel="noopener noreferrer">Rock Choir</a>. 
              The people are friendly and welcoming, and I really enjoy the time I spend with those that 
              enjoy singing as much as I do.</p>
            <p>I have had the opportunity to sing at some amazing places, including Abbey Road, 
              Blenheim Palace, Proms in the Park and the Edinburgh Fringe Festival. 
              I have even appeared on TV!</p>
          </div>
        </li>
        <hr />
        <li>
          <ReadiophonicsImage />
          <div>
            <p>In September 2019 I joined a local a cappella group called Readiophonics.</p>
            <p>The challenge of using only our voices to create an exciting and imaginative 
              arrangement of modern songs is one that I thrive on, 
              and providing a valuable input into the creative process whilst working together 
              with others in the group has been very fulfilling.</p>
            <p>We have extended our expertise and experience by creating arrangements online and 
              creating videos to showcase our style.
            </p>
          </div>
        </li>
        <hr />
        <li>
          <a href="http://rva.org.uk/" title="Reading Voluntary Action" target="_blank" rel="noopener noreferrer">
            <RVAImage />
          </a>
          <div>
            <p>In June 2018 I joined Reading Voluntary Action to assist with their <a href="http://go-reading.org/" title="Get Online" alt="Get Online">Get Online</a> programme. 
               This helps those that require assistance with getting online, as well as computer, tablet and smartphone related issues.
            </p>
            <p>During 2021 I have assisted at the local vaccination hub, and I am supporting the 2021 Census.</p>
            <p>
            </p>
          </div>
        </li>
        <hr />
        <li>
          <a href="https://www.ingress.com/" title="Ingress" target="_blank" rel="noopener noreferrer">
            <IngressImage />
          </a>
          <div>
            <p>Augmented reality games have always interested me, and I have been a member of the game <a href="https://www.ingress.com/" title="Ingress" target="_blank" rel="noopener noreferrer">Ingress</a> for 
              5 years. During my time playing for <a href="https://www.willbe.blue" title="The Resistance" target="_blank" rel="noopener noreferrer">the 
              Resistance faction</a>, I have met agents from all over the world, 
              working together on the virtual battlefield whilst travelling to new countries, 
              leading teams of agents into battle in anomaly events and being an invaluable 
              member of the local and national community of agents.</p>
          </div>
        </li>
        <hr />
        <li>
          <a href="https://staceystockwelltraining.com/" title="Stacey Stockwell" target="_blank" rel="noopener noreferrer">
            <SignLanguageImage />
          </a>
          <div>
            In 2019 a friend from Rock Choir, <a href="https://staceystockwelltraining.com/" title="Stacey Stockwell" target="_blank" rel="noopener noreferrer">Stacey Stockwell</a>, invited me to join a class she runs which teaches sign language. Within 12 weeks I have 
            learnt how to have conversations in sign language and have signed several songs.
          </div>
        </li>
      </ul>
    </section>
  </Layout>
)

export default IndexPage
