import PropTypes from "prop-types"
import React from "react"

import ExternalNav from "./externalNav"
import { TitleImage } from "./images";

export default class Header extends React.Component {
  state = { x: 0, y: 0, headerStyle: '' };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    console.log(this.props);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = ev => {
    this.setState({
      x: window.scrollX,
      y: window.scrollY,
      headerStyle: window.scrollY > 0 ? "opaque" : ""
    });
    console.log(this.state);
  };

  render() {
    return (<header className={this.state.headerStyle}>
      <TitleImage />
      <ExternalNav />
    </header>);
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
