import React from "react"

const ExternalNav = () => (
    <nav className="external">
        <ul>
            <li><a href="https://www.twitter.com/yeggstry" class="icon-social-twitter" title="Twitter" target="_blank" rel="noopener noreferrer"> </a></li>
            <li><a href="https://www.linkedin.com/in/lewisrkeen" class="icon-social-linkedin" title="LinkedIn" target="_blank" rel="noopener noreferrer"> </a></li>
            <li><a href="https://www.github.com/yeggstry" class="icon-social-github" title="Github" target="_blank" rel="noopener noreferrer"> </a></li>
        </ul>
    </nav>
)

export default ExternalNav
