import React from "react"
import { StaticImage } from "gatsby-plugin-image";

export const TitleImage = () => (
  <StaticImage 
    src="../images/yeggstry-title.png"
    alt="Yeggstry - Making lives easier through technology"
    className="title"
    width="325"
    height="105" 
    formats={["auto", "webp", "avif"]} 
    loading="eager" 
  />
);

export const AboutImage = () => (
  <StaticImage 
    src="../images/jimmy_white_red_ball_tongue.png"
    className="bg-image"
    width="350"
    formats={["auto", "webp", "avif"]} 
    loading="eager" 
  />
);

export const GamesImage = () => (
  <StaticImage 
    src="../images/back4blood.png"
    className="bg-image"
    width="350"
    formats={["auto", "webp", "avif"]} 
    loading="eager" 
  />
);


export const RockChoirImage = () => (
  <StaticImage 
    src="../images/rock_choir.png"
    width="150"
    objectFit="contain"
    alt="Rock Choir"
    formats={["auto", "webp", "avif"]} 
  />
);

export const ReadiophonicsImage = () => (
  <StaticImage 
    src="../images/readiophonics.png"
    width="150"
    objectFit="contain"
    alt="Readiophonics"
    formats={["auto", "webp", "avif"]} 
  />
);

export const RVAImage = () => (
  <StaticImage 
    src="../images/rva_small_transparent.png"
    alt="Reading Voluntary Action"
    formats={["auto", "webp", "avif"]} 
  />
);

export const IngressImage = () => (
  <StaticImage 
    src="../images/Ingress_Logo_vector.svg.png"
    width="150"
    objectFit="contain"
    alt="Ingress"
    formats={["auto", "webp", "avif"]} 
  />
);

export const SignLanguageImage = () => (
  <StaticImage 
    src="../images/sign_language.jpg"
    width="150"
    objectFit="contain"
    alt="Sign Language"
    formats={["auto", "webp", "avif"]} 
  />
);

